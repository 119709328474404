<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="container-fluid py-4 add-student grade-detail paid-remain">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    {{$t('message.studentsCount')}}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-12 tab-div tab2">
            </div>
            <!-- exceptional attendance row -->
            <div class="" id="">
              <div

              >
               <div
              >
                <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label> {{$t('message.fromDate')}} <span class="red">*</span></label>
                     <vmd-input id="" type="month" v-model="fromDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> {{$t('message.toDate')}} <span class="red">*</span></label>
                     <vmd-input id="" type="month" v-model="toDate" />
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="primary"
                      @click="filterData()"
                    >
                      {{$t('message.filter')}}</vmd-button
                    >
                  </div>
                  <!-- <div class="text-center col-lg-1">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="info"
                      @click="printReport('year')"
                    >
                      {{$t('message.print')}}</vmd-button
                    >
                  </div> -->
                  <!-- <div class="text-center col-lg-1">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="warning"
                      @click="downloadFile('month')"
                    >
                      اكسل</vmd-button
                    >
                  </div> -->
                </div>
                <div
                  id="print-report"
                  style="margin-top: 40px; "
                >
                  <div
                    class="row align-center"
                    style="margin-top: 40px;"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"

                    >
                      <table class="b-block table" >
                        <thead>
                          <tr>
                             <th > {{$t('message.month')}}</th>
                            <th >  {{$t('message.totalNumbers')}} </th>
                             <th >  {{$t('message.interested')}} </th>
                             <th >  {{$t('message.notInterested')}} </th>
                            <th > {{$t('message.rubbish')}}</th>
                             <th > {{$t('message.actuallyCome')}}</th>
                              <th > {{$t('message.actuallyBooked')}}</th>
                            <th > {{$t('message.totalPaid')}}</th>
                          </tr>
                        </thead>
                         <tbody>
                          <tr
                            v-for="(value, key) in monthlyPlacement"
                            :key="key"
                          >
                           <td class="table-success">{{ key}}</td>
                             <td class="back-color total-tex">{{value.total_students }}</td>
                           <td >{{ value.interested}}</td>
                            <td >{{ value.not_interested}}</td>
                             <td >{{ value.rubbish}}</td>
                             <td >{{ value.total_tests}}</td>
                              <td >{{ value.total_booked}}</td>
                              <td class="back-color total-tex" >{{ $filters.number(value.paid)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end -->
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import exportFromJSON from 'export-from-json'
import $ from 'jquery'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'Students',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      fromDate: '',
      toDate: '',
      branches: [],
      monthlyPlacement: [],
      errors: []
    }
  },
  created () {
  },
  methods: {
    calculateTotal (value) {
      return this.$filters.number(value.placement_paid + value.course_paid)
    },
    filterData (data) {
      this.$http
        .get(
                `${this.$hostUrl}report/placements/students/count?from=${this.fromDate}&to=${this.toDate}&group_by=day`
        )
        .then((response) => {
          this.monthlyPlacement = response.data.data
          // const firstDay = Object.keys(this.monthlyPlacement)[0]
          // this.branches = Object.keys(this.monthlyPlacement[firstDay])
          //   .filter(subkey => !subkey.includes('total'))
        })
    },
    downloadFile (report) {
      if (report === 'instructor') {
        $.each(this.students, (key, val) => {
          this.studentsFile.push({
            رقم: val.number,
            الاسم: val.full_name,
            الهاتف: val.mobile,
            المجموعة: val.group_name,
            تكلفة_الدورة: val.course_cost,
            الحضور: val.attend,
            المسدد: val.payed,
            المتبقي: val.remain
          })
        })
        const data = this.studentsFile
        const fileName = ` تقرير المسدد والمتبقي للمدرس : ${this.selectedInstructor.name}- ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
      if (report === 'course') {
        $.each(this.courseStudents, (key, val) => {
          this.studentsFile.push({
            مسلسل: key + 1,
            رقم: val.number,
            الاسم: val.full_name,
            الهاتف: val.mobile,
            المجموعة: val.group_name,
            تكلفة_الدورة: val.course_cost,
            الحضور: val.attend,
            المسدد: val.paid,
            المتبقي: val.remain
          })
        })
        const data = this.studentsFile
        const fileName = ` تقرير المسدد والمتبقي دورة : ${this.course}  - ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    },
    printReport (data) {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      if (data === 'instructor') {
        setTimeout(this.$htmlToPaper('print-report', options), 10000)
        $('.card-footer').show()
      } else {
        setTimeout(this.$htmlToPaper('print-report2', options), 10000)
        $('.card-footer').show()
      }
    }
  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.width {
  width: 100% !important;
}
.back-color{
  background-color: #f8f9fa !important
}
.total-tex{
  font-weight: bold;
  font-size:16px !important;
  font-family: Montserrat, sans-serif !important;
}
</style>
